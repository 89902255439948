import styled from '@emotion/styled'
import slugify from '@sindresorhus/slugify'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Container from '../../../components/Container'
import { SubTitleStyled, TitleStyled } from '../../../components/common/Title'
import { useIntlLabel } from '../../../intl'
import ItemNewService from './ItemNewService'
import { HomeServiceNewSectionQuery } from './__generated__/HomeServiceNewSectionQuery'

const ContainerStyled = styled.section((props) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0',
    boxSizing: 'border-box',
}))

const ContainerListStyled = styled.div((props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: `0 -${props.theme.spacing(1)}`,
}))

const HomeServiceNewSection: FC = () => {
    const intl = useIntl()
    const getIntlLabel = useIntlLabel()

    const { allStrapiService, strapiHome } = useStaticQuery<HomeServiceNewSectionQuery>(
        graphql`
            query HomeServiceNewSectionQuery {
                allStrapiService(limit: 5, sort: { order: DESC, fields: created_at }) {
                    edges {
                        node {
                            serviceDescription
                            serviceName
                            serviceName_mg
                            serviceSlug
                            serviceSlug_mg
                            serviceCategories {
                                categoryName
                                categoryName_mg
                            }
                        }
                    }
                }
                strapiHome {
                    titleNewServices
                    titleNewServices_mg
                }
            }
        `,
    )

    return (
        <ContainerStyled>
            <Container>
                <TitleStyled>{getIntlLabel(strapiHome, 'titleNewServices')}</TitleStyled>
                <SubTitleStyled>
                    {intl.formatMessage({ id: 'home_new_service_subtitle' })}
                </SubTitleStyled>
                <ContainerListStyled>
                    {allStrapiService?.edges?.map?.((item, index: number) => (
                        <ItemNewService
                            key={`${item}-${index}`}
                            to={`/services/${item.node.serviceSlug}?category=${slugify(
                                item.node?.serviceCategories?.[0]?.categoryName ?? '',
                            )}`}
                            title={getIntlLabel(item.node, 'serviceName')}
                            category={
                                getIntlLabel(item?.node?.serviceCategories?.[0], 'categoryName') ??
                                ''
                            }
                        />
                    ))}
                </ContainerListStyled>
            </Container>
        </ContainerStyled>
    )

    return null
}

export default HomeServiceNewSection
